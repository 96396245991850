import React from "react";
import PropTypes from "prop-types";

const StepWrapper = ({
  ariaControls,
  buttons,
  children,
  step,
  stepCurrent,
  stepTotal,
  stepLabel,
  title,
  subtitle,
  buttonGroupClassName = "m-quiz__button-group m-quiz__button-group--end",
  footer,
  footerLink,
}) => {
  if (step !== stepCurrent) {
    return null;
  }

  return (
    <div aria-live="polite">
      <p className="a-hidden">
        Step {step} of {stepTotal} {stepLabel && `: ${stepLabel}`}
      </p>
      {step === stepTotal ? (
        <>
          <h3 className="m-quiz__heading">{title}</h3>
          <div className="m-quiz__body">{children}</div>
          <div className="m-quiz__note">{subtitle}</div>
        </>
      ) : (
        <fieldset className="m-quiz__fieldset">
          <legend className="m-quiz__heading">{title}</legend>
          <div className="m-quiz__body">{children}</div>
        </fieldset>
      )}
      <div
        role="group"
        aria-controls={ariaControls}
        className={buttonGroupClassName}
      >
        {buttons}
      </div>
      {footer && (
        <div className="m-quiz__note">
          {footer} {footerLink}.
        </div>
      )}
    </div>
  );
};

StepWrapper.propTypes = {
  ariaControls: PropTypes.string.isRequired,
  buttons: PropTypes.node.isRequired,
  buttonGroupClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  step: PropTypes.number.isRequired,
  stepCurrent: PropTypes.number.isRequired,
  stepTotal: PropTypes.number.isRequired,
  stepLabel: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  footer: PropTypes.node,
  footerLink: PropTypes.string,
};

export default StepWrapper;
