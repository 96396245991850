import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import StepWrapper from "../StepWrapper";
import RadioGroup from "../RadioGroup";
import EarningsInput from "./EarningsInput";
import Button from "../Button";
import content from "./content";
import { currencyFormatter, quarterlyToWeeklyBenefits } from "./formulas";
import isNil from "lodash/isNil";

const canCalculateScroll =
  document.documentElement.getBoundingClientRect().y &&
  window.CSS &&
  CSS.supports("color", "var(--fake-var)");

const BenefitsCalculator = ({
  lang,
  learnself: learnSelfUrl,
  learnother: learnOtherUrl,
  learndisclaimer: learnDisclaimerUrl,
}) => {
  const [step, setStep] = useState(1);
  const [status, setEmploymentStatus] = useState("");
  const [earningsOption, setEarningsOption] = useState("");
  const [estimateEnabled, setEstimateEnabled] = useState(false);
  const [benefits, setBenefits] = useState(0);

  const isMount = useRef(true);
  const formEl = useRef(null);

  useEffect(() => {
    if (isNil(canCalculateScroll)) return;
    // don't run effect on component mount
    if (isMount.current) {
      isMount.current = false;
      return;
    }
    let scrollY = 0;
    if (formEl && formEl.current) {
      const elY = formEl.current.getBoundingClientRect().y;
      const windowOffset = window.pageYOffset;
      const headerHeight = parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue(
          "--site-header-height"
        )
      );
      // very simplified implementation of fluidScale SCSS mixin
      const padding = Math.min(100, Math.max(40, window.innerWidth / 13.415));
      scrollY = elY + windowOffset - headerHeight - padding;
    }

    try {
      window.scrollTo({ top: scrollY, behavior: "smooth" });
    } catch {
      // Fallback
      window.scrollTo(0, scrollY);
    }
  }, [step]);

  const handleRadioChange = (value) => {
    setBenefits(0);
    setEarningsOption(value);
    setEstimateEnabled(false);
  };
  const handleTotalChange = (value) => {
    setEstimateEnabled(!!value);
    setBenefits(quarterlyToWeeklyBenefits(value));
  };
  const handleStepChange = (newStep) => {
    setStep(newStep);
    setEstimateEnabled(false);
  };
  const handleReset = () => {
    setStep(1);
    setEmploymentStatus("");
    setEarningsOption("");
    setBenefits(0);
  };

  const formattedBenefits = currencyFormatter.format(benefits);
  const { label, stepOne, stepTwo, stepThree } = content[lang];
  const { learnMoreLinkText } = stepThree;
  const learnMore = {
    text: status === "self" ? learnMoreLinkText.self : learnMoreLinkText.other,
    url: status === "self" ? learnSelfUrl : learnOtherUrl,
  };
  const learnMoreDisclaimer = {
    text: lang === "es" ? "Más información en" : "Learn more",
    url: learnDisclaimerUrl,
  };

  return (
    <form
      id="step-info"
      role="region"
      ref={formEl}
      aria-live="polite"
      className="m-quiz"
    >
      <StepWrapper
        ariaControls="step-info"
        title={stepOne.title}
        step={1}
        stepCurrent={step}
        stepTotal={3}
        stepLabel="Employment Status"
        buttons={
          <Button
            arrowRight
            onClick={() => handleStepChange(step + 1)}
            label={label.next}
            type="submit"
            disabled={!status}
          />
        }
      >
        <RadioGroup
          name="employmentOptions"
          options={stepOne.radioOptions}
          active={status}
          onChange={(value) => setEmploymentStatus(value)}
        />
      </StepWrapper>
      <StepWrapper
        ariaControls="step-info"
        title={stepTwo.title[status] || ""}
        step={2}
        stepCurrent={step}
        stepTotal={3}
        stepLabel="Total Earnings"
        buttons={
          <>
            <Button
              arrowLeft
              onClick={() => handleStepChange(step - 1)}
              label={label.back}
            />
            <Button
              arrowRight
              onClick={() => handleStepChange(step + 1)}
              disabled={!estimateEnabled}
              label={label.estimate}
              type="submit"
            />
          </>
        }
      >
        <EarningsInput
          status={status}
          activeOption={earningsOption}
          onRadioChange={handleRadioChange}
          onTotalChange={handleTotalChange}
          lang={lang}
        />
        {status === "hourlyFixed" && (
          <small className="m-quiz__note">{stepTwo.hourlyFixedNote}</small>
        )}
      </StepWrapper>
      <StepWrapper
        ariaControls="step-info"
        title={`${stepThree.title} ${formattedBenefits}.`}
        subtitle={`${stepThree.subtitle}`}
        step={3}
        stepCurrent={step}
        stepTotal={3}
        stepLabel="Complete"
        buttons={
          <>
            <a href={learnMore.url} className="m-button m-button--dark-blue">
              {learnMore.text}
            </a>
            <Button onClick={handleReset} label={label.restart} />
          </>
        }
        buttonGroupClassName="m-quiz__button-group m-quiz__button-group--start"
        footer={stepThree.disclaimer}
        footerLink={
          <a href={learnMoreDisclaimer.url}>{learnMoreDisclaimer.text}</a>
        }
      >
        {status === "self" && <p>{stepThree.selfEmployedNote}</p>}
      </StepWrapper>
    </form>
  );
};

BenefitsCalculator.propTypes = {
  lang: PropTypes.oneOf(["en", "es"]),
  learnself: PropTypes.string.isRequired,
  learnother: PropTypes.string.isRequired,
  learndisclaimer: PropTypes.string.isRequired,
};

BenefitsCalculator.defaultProps = {
  lang: "en",
};

export default BenefitsCalculator;
